import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import NavbarTop from "../components/navbar-top"

import Valores from "../sections/valores"
import Nosotros from "../sections/nosotros"
import Abogados from "../sections/abogados"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Nosotros" />
    <NavbarTop />
    <PageHeader title="Nosotros" bg={data.file.childImageSharp.fluid} />
    <Nosotros />
    <Abogados />
    <Valores />
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "nosotros.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
