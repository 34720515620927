import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styles from "./abogados.module.scss"

export default props => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//abogados//" } }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            excerpt
            html
            frontmatter {
              title
              foto {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const abogados = data.edges.map(({ node }) => ({
    html: node.html,
    ...node.frontmatter,
  }))

  return (
    <section
      id="abogados"
      style={{
        backgroundColor: "#f2f2f2",
      }}
    >
      <div className="container-sm pt-5">
      <h1 className="mb-5 text-muted">
          Abogados</h1>
        {abogados.map((abogado, index) => (
          <div
            className="d-flex flex-wrap flex-lg-nowrap justify-content-between"
            style={{
              flexDirection: index % 2 == 0 ? "row" : "row-reverse",
              textAlign: index % 2 == 0 ? "right" : "left",
            }}
          >
            <div className="col-lg-5 px-0">
              <Img
                className="my-5"
                style={{
                  minHeight: 400,
                }}
                fluid={abogado.foto.childImageSharp.fluid}
              />
            </div>
            <div className="col-lg-6 px-0 py-3">
              <h2
                style={{
                  textTransform: "uppercase",
                  backgroundColor: "#f2f2f2",
                  borderBottom: "1px solid #ececec",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  position: "sticky",
                  top: 0,
                }}
              >
                {abogado.title}
              </h2>
              <div style={{
                fontSize: 17,
              }} dangerouslySetInnerHTML={{ __html: abogado.html }} />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
