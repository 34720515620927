import React from "react"
import styles from "./valores.module.scss"
import { useStaticQuery, graphql, Link } from "gatsby"

export default props => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//valores//" } }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            excerpt
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)
  const valores = data.edges.map(({ node }) => ({
    html: node.html,
    ...node.frontmatter,
  }))
  return (
    <section
      style={{
        backgroundColor: "#ffffff",
        paddingTop: "5rem",
        paddingBottom: "5rem",
        minHeight: "360px",
      }}
    >
      <div className="container-sm">
        <h1 className="mb-4 text-muted">
          Nuestros Valores
        </h1>
        <div className={styles.flexContainer}>
          {valores.map((item, i) => (
            <div className={styles.flexItem} style={{
              borderRightWidth: (i === valores.length - 1) ? '0px' : '3px'
            }}>
              <h3 className={"mb-3 text-primary text-uppercase "+ styles.itemTitle}>{item.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: item.html }} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
