import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/nosotros.md/" }) {
        html
        excerpt
      }
    }
  `)
  const { markdownRemark } = data

  return (
    <section
      id="nosotros"
      className="container-sm d-flex"
      style={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
        minHeight: `30vh`,
      }}
    >
      
      <div
        style={{
          maxWidth: "800px",
        }}
        dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
      />
    </section>
  )
}
